<template>
  <div>
    <v-card>
      <v-card-title>
        Logs
      </v-card-title>
      <div>
        <v-sheet
          tile
          height="54"
          color="grey lighten-3"
          class="d-flex"
        >
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <div class="d-flex justify-center align-center">
            <div class="text--secondary font-weight-medium text-uppercase">
              {{ $t('logs.calendarTitle') }}
            </div>
          </div>
          <v-spacer />
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="340">
          <v-calendar
            ref="calendar"
            v-model="date"
            :weekdays="[0, 1, 2, 3, 4, 5, 6]"
            type="month"
            :events="events"
            event-color="grey"
            event-overlap-mode="stack"
            :event-overlap-threshold="30"
            :locale="this.$i18n.locale"
          />
        </v-sheet>
      </div>
      <br>
      <div
        class="
          d-flex
          justify-center
          align-center
          text--secondary
          font-weight-medium
          text-uppercase
        "
      >
        <div>{{ $t('logs.filterTitle') + ' ' + search }}</div>
      </div>
      <v-card-subtitle class="d-flex flex-row align-center justify-center flex-wrap">
        <div
          v-for="(type, index) in logTypes"
          :key="index"
        >
          <v-chip
            x-small
            chip
            :color="getColor(type)"
            dark
            @click="logTypesFilter(type)"
          >
            {{ type }}
          </v-chip>
        </div>
      </v-card-subtitle>
      <v-card-subtitle class="d-flex flex-row align-center justify-center">
        <div v-if="search">
          <v-chip
            small
            chip
            color="grey"
            @click="clearFilter"
          >
            {{ $t('logs.buttonClearFilter') }}
          </v-chip>
        </div>
      </v-card-subtitle>

      <v-card-subtitle>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('logs.table.search')"
          single-line
          hide-details
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="logs"
        :search="search"
        :locale="this.$i18n.locale"
      >
        <template v-slot:[`item.timestamp`]="{item}">
          <div class="text-no-wrap">
            {{ item.timestamp }}
          </div>
        </template>
        <template v-slot:[`item.type`]="{item}">
          <v-chip
            x-small
            :color="getColor(item.type)"
            dark
          >
            {{ item.type }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'Logs',
  data: () => ({
    search: '',
    logs: [{
      timestamp: '',
      type: '',
      message: '',
    }],
    date: '',
    logTypes: ['INFO', 'EMERGENGY', 'CRITICAL', 'ALERT', 'ERROR', 'WARNING', 'NOTICE', 'DEBUG'],
    events: [],
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('logs.table.timestamp'),
          value: 'timestamp',
          align: 'start',
        },
        {
          text: this.$t('logs.table.type'),
          value: 'type',
        },
        {
          text: this.$t('logs.table.message'),
          value: 'message',
        },
      ];
    },
  },
  watch: {
    date() {
      this.getLogs();
    },
  },
  mounted() {
    this.getLogs();
  },
  methods: {
    async getLogs() {
      try {
        await this.$axios.get(`${process.env.VUE_APP_URL_API}/logs?date=${this.date}`, { headers: { Authorization: `Bearer ${router.app.$keycloak.token}` } })
          .then((response) => {
            if (response.data.data) {
              this.logs = response.data.data.logs;
            } else {
              this.logs = [];
            }
            this.events = response.data.data.available_log_dates.map((date) => ({
              name: '',
              start: date,
            }));
          })
          .catch((error) => {
            console.log('getLogs', error);
          });
      } catch (err) {
        console.warn('getLogs', err);
      }
    },
    getColor(type) {
      if (type === 'INFO') return 'blue';
      if (type === 'EMERGENGY') return 'black';
      if (type === 'CRITICAL') return 'deep-orange darken-4';
      if (type === 'ALERT') return 'cyan';
      if (type === 'ERROR') return 'red';
      if (type === 'WARNING') return 'orange';
      if (type === 'NOTICE') return 'brown darken-1';
      if (type === 'DEBUG') return 'green';
      return 'transparent';
    },
    logTypesFilter(type) {
      this.search = type;
    },
    clearFilter() {
      this.search = '';
    },
  },
};
</script>
